export default {
  "name": "ts-ebml",
  "version": "2.0.2",
  "description": "ebml decoder and encoder",
  "scripts": {
    "setup": "npm install -g http-server;",
    "init": "npm run update; npm run mkdir; npm run build",
    "update": "npm run reset; npm update",
    "reset": "rm -rf node_modules",
    "mkdir": "mkdir lib dist 2>/dev/null",
    "clean": "rm -rf lib/* dist/* test/*.js; mkdir -p dist",
    "build": "npm run clean   && tsc    -p .; npm run browserify",
    "start": "http-server . -s & tsc -w -p .& watchify lib/example_seekable.js -o test/example_seekable.js",
    "stop": "killall -- node */tsc -w -p",
    "browserify": "browserify lib/index.js --standalone EBML -o dist/EBML.js",
    "watchify": "watchify lib/index.js --standalone EBML -o dist/EBMl.js -v",
    "test": "tsc; espower lib/test.js > lib/test.tmp; mv -f lib/test.tmp lib/test.js; browserify lib/test.js -o test/test.js",
    "example": "tsc; browserify lib/example_seekable.js -o test/example_seekable.js",
    "examples": "tsc; for file in `find lib -name 'example_*.js' -type f -printf '%f\\n'`; do browserify lib/$file -o test/$file; done",
    "examples_bsd": "tsc; for file in `find lib -name 'example_*.js' -type f -print`; do browserify lib/$(basename $file) -o test/$(basename $file); done",
    "check": "tsc -w --noEmit -p ./",
    "lint": "tslint -c ./tslint.json --project ./tsconfig.json --type-check",
    "doc": "typedoc --mode modules --out doc --disableOutputCheck"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/legokichi/ts-ebml.git"
  },
  "keywords": [
    "ebml",
    "webm",
    "mkv",
    "matrosika",
    "webp"
  ],
  "author": "legokichi duckscallion",
  "license": "MIT",
  "bugs": {
    "url": "https://github.com/legokichi/ts-ebml/issues"
  },
  "homepage": "https://github.com/legokichi/ts-ebml#readme",
  "dependencies": {
    "buffer": "^5.0.7",
    "commander": "^2.11.0",
    "ebml": "^2.2.1",
    "ebml-block": "^1.1.0",
    "events": "^1.1.1",
    "int64-buffer": "^0.1.9",
    "matroska": "^2.2.3"
  },
  "devDependencies": {
    "@types/commander": "^2.9.1",
    "@types/qunit": "^2.0.31",
    "browserify": "^13.1.0",
    "empower": "^1.2.3",
    "espower-cli": "^1.1.0",
    "power-assert": "^1.4.4",
    "power-assert-formatter": "^1.4.1",
    "qunit-tap": "^1.5.1",
    "qunitjs": "^2.4.0",
    "tslint": "^3.15.1",
    "typedoc": "^0.5.3",
    "typescript": "^2.4.2",
    "watchify": "^3.7.0"
  },
  "bin": "./lib/cli.js",
  "main": "./lib/index.js",
  "typings": "./lib/index.d.ts"
}
;