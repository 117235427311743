import { dew as _EBMLDecoderDew } from "./EBMLDecoder";
import { dew as _EBMLEncoderDew } from "./EBMLEncoder";
import { dew as _EBMLReaderDew } from "./EBMLReader";
import { dew as _toolsDew } from "./tools";
import _package from "../package.json";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var EBMLDecoder_1 = _EBMLDecoderDew();

  exports.Decoder = EBMLDecoder_1.default;

  var EBMLEncoder_1 = _EBMLEncoderDew();

  exports.Encoder = EBMLEncoder_1.default;

  var EBMLReader_1 = _EBMLReaderDew();

  exports.Reader = EBMLReader_1.default;

  var tools = _toolsDew();

  exports.tools = tools;
  var version = _package.version;
  exports.version = version;
  return exports;
}